/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import TranslateComponent from "../../misc/translate/Translate.component";
import {
  ASCENDING_SORT,
  DESCENDING_SORT,
  PAGE_TABLE_TITLE,
  PRIIP_COLUMN_DEFINITIONS,
  PRIIP_COPY_TO_CLIPBOARD,
  PRIIP_SEARCH_PLACEHOLDER,
  PRIIP_SEARCH_TITLE_LABEL,
  PRIIP_TABLE_PAGE_SIZE,
} from "../../../constants/Priip.constants";

import {useIntl} from "react-intl";

import {NO_RESULTS} from "../../../constants/Notes.constants";
import SimpleSearchComponent from "../../misc/search/SimpleSearch.component";
import TableComponent from "../../misc/table/Table.component";
import PriipService, {
  handleCopyPriip,
  priipDownload,
} from "../../../services/Priip.service";
import PaginatorComponent from "../../pagination/Paginator.component";
import SpinnerComponent from "../../spinner/Spinner.component";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import LanguageService from "../../../services/Language.service";
import ModalComponent from "../../misc/modal/Modal.component";

function PriipTable() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState();
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: ASCENDING_SORT,
  });
  const copyModalRef = useRef();
  const intl = useIntl();
  const history = useHistory();

  const getLanguage = (code) => {
    switch (code) {
      case "en":
        return "LANGUAGE_en";
      case "bg":
        return "LANGUAGE_bg";
      case "cs":
        return  "LANGUAGE_cs";
      case "de":
        return "LANGUAGE_de";
      case "el":
        return "LANGUAGE_el";
      case "es":
        return "LANGUAGE_es";
      case "fi":
        return "LANGUAGE_fi";
      case "fr":
        return "LANGUAGE_fr";
      case "hr":
        return "LANGUAGE_hr";
      case "hu":
        return "LANGUAGE_hu";
      case "it":
        return "LANGUAGE_it";
      case "nl":
        return "LANGUAGE_nl";
      case "no":
        return "LANGUAGE_no";
      case "pl":
        return "LANGUAGE_pl";
      case "pt":
        return "LANGUAGE_pt";
      case "ro":
        return "LANGUAGE_ro";
      case "sk":
        return "LANGUAGE_sk";
      default:
        return "LANGUAGE_sk";
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const handleDownload = (id, name) => {
    priipDownload(id, name);
  };
  useEffect(() => {
    PriipService.getPriipData(currentPage)
      .then((data) => generateTableData(data))
      .catch((err) => console.error(err));
  }, [currentPage]);

  const handleSearch = (e) => {
    PriipService.getPriipSearchData(e, currentPage)
      .then((data) => generateTableData(data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (!history?.goBackFlag) {
      setCurrentPage(1);
      history.page = 1;
    } else {
      setCurrentPage(history.page);
      if (history?.goBackFlag) {
        history.goBackFlag = false;
      }
    }
  }, [history, intl]);
  const handleCopy = (id) => {
    handleCopyPriip(id);
    copyModalRef.current.showModal();
  };

  const handleColSort = (key) => {
    let sortedData = [...data];
    let direction = ASCENDING_SORT;

    if (sortConfig.key === key && sortConfig.direction === ASCENDING_SORT) {
      direction = DESCENDING_SORT;
      sortedData = sortedData.sort((a, b) => {
        if (key === "isin") {
          return b.isin.toString().localeCompare(a.isin);
        } else if (key === "createdAt") {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }
        return 0;
      });
    } else {
      sortedData = sortedData.sort((a, b) => {
        if (key === "isin") {
          return a.isin.toString().localeCompare(b.isin);
        } else if (key === "createdAt") {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
        return 0;
      });
    }
    setSortConfig({key, direction});
    setData(sortedData);
  };

  const generateTableData = (data) => {
    if (data.results.length < 1) {
      setNoResults(() => true);
    } else {
      setNoResults(() => false);

      setLoading(() => true);
      const dataWithActions = data.results.map((row) => ({
        ...row,
        fileLanguage : <TranslateComponent label={getLanguage(row.fileLanguage) }/> ,
        action: (
          <div className="tds-priip-actions">
            <button
              onClick={() => {
                handleCopy(row.id);
              }}
              className="td-icon td-icon-insights"
            ></button>
            <button
              onClick={() => {
                handleDownload(row.id, row.isin);
              }}
              className="td-icon td-icon-download"
            ></button>
          </div>
        ),
      }));
      setTotalResults(data.total);
      setData(dataWithActions);
      setLoading(() => false);
    }
  };
  return (
    <section>
      <div className="tds-priip-table-container">
        <div className="tds-priip-table-header">
          <h3>
            <TranslateComponent label={PAGE_TABLE_TITLE} />
          </h3>
        </div>
        <div className="tds-priip-searchWrapper">
          <SimpleSearchComponent
            id="kidSearch"
            className="search-section"
            placeholder={LanguageService.translate(PRIIP_SEARCH_PLACEHOLDER)}
            onSearch={handleSearch}
            label={LanguageService.translate(PRIIP_SEARCH_TITLE_LABEL)}
            hideLabel={true}
          />
        </div>
        {!loading & !noResults ? (
          <>
            <div className="lifecycle-event-table-wrapper">
              <TableComponent
                columns={PRIIP_COLUMN_DEFINITIONS}
                data={data}
                onSort={handleColSort}
              />
            </div>
          </>
        ) : null}
        {loading && <SpinnerComponent />}
      </div>
      {noResults && (
        <div className="tds-current-offering-search-results-description">
          <TranslateComponent label={NO_RESULTS} />
        </div>
      )}
      <PaginatorComponent
        className="event-search-table-paginator"
        currentPage={currentPage}
        resultsPerPage={PRIIP_TABLE_PAGE_SIZE}
        total={totalResults}
        goToPage={goToPage}
        key={`${currentPage} - ${totalResults}`}
      />

      <ModalComponent
        ref={copyModalRef}
        id={"noteDetailsModal"}
        className="note-details-modal-content"
      >
        <TranslateComponent label={PRIIP_COPY_TO_CLIPBOARD} />
      </ModalComponent>
    </section>
  );
}

export default React.memo(PriipTable);
