import React from "react";
import HeaderNavComponent from "./header/HeaderNav.component";
import FourOFourPageComponent from "./four-o-four-page/FourOFourPage.component";
import PreviousOfferingComponent from "./previous-offerings/PreviousOfferings.component";
import FooterComponent from "./footer/Footer.component";
import RouteChangeDetectorComponent from "./misc/route-change-detector/RouteChangeDetector.component";
import EnvironmentService from "../services/Environment.service";
import LanguageService from "../services/Language.service";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import CurrentOfferingComponent from "./current-offerings/CurrentOfferings.component";
import HomePageComponent from "./home/HomePage.component";
import { 
  ROUTE_PATH_CURRENT_OFFERINGS, 
  ROUTE_PATH_EVENTS, ROUTE_PATH_HOME, 
  ROUTE_PATH_PREVIOUS_OFFERINGS, 
  ROUTE_PATH_NOTE_DETAILS,
  ROUT_PATH_404_PAGE,
  ROUTE_PATH_EVENTS_CORPORATE,
  ROUTE_PATH_CONTACT_US,
  ROUTE_PATH_SEARCH,
  ROUTE_PATH_SN_LEGAL_DISCLOSURE,
  ROUTE_PATH_INVESTORS_INFO,
  ROUTE_PATH_RESOURCES_INVESTOR_FRAMEWORK,
  ROUTE_PATH_RESOURCES_PRODUCT_INFORMATION,
  ROUTE_PATH_RESOURCES_FAQS,
  ROUTE_PATH_PRINT, ROUTE_PATH_PREFERENCES,
  ROUTE_PATH_PRIIP_PAGE
} from "../constants/Routes.constants";
import EventsPageContainerComponent from "./events/EventsPageContainer.component";
import ProductDetailsPageComponent from "./product-details/ProductDetailsPage.component";
import CorporateEventDetailsPageComponent from "./events/CorporateEventDetailsPage.component";

import EnumService from "../services/Enum.service";
import ContactUsComponent from "./footer/contact-us/ContactUs.component";
import SNLegalDisclosureComponent from "./footer/sn-legal-disclosures/SNLegalDisclosure.component";
import InvestorsInfoComponent from "./footer/investor-info/InvestorInfo.component";
import SearchComponent from "./search/Search.component";
import {IntlProvider} from "react-intl";

import en from '../language/en.json';
import fr from '../language/fr.json';
import InvestorFrameworkComponent from "./resources/InvestorFramework.component";
import ProductInformationComponent from "./resources/ProductInformation.component";
import FaqsComponent from "./resources/Faqs.component";
import PrintComponent from "./product-details/note-prints/Print.component";
import Preferences from "./preferences/Preferences.component";
import CookieConsentBanner from "./cookie-consent-banner/CookieConsentBanner.component";
import { reportWebVitals } from "../reportWebVitals";
import { disableGAByDeclinedCookieConsent, enableDisableGA, enableGA } from "../services/GoogleAnalytics.service";
import Priip from "./priip-component/Priip.component";

const messages = {
  en,
  fr
}

export default class AppComponent extends React.Component {

  notesAppbasePath = process.env.PUBLIC_URL;

  constructor(props) {
    super(props);
    EnvironmentService.initialize();
    LanguageService.initializeViaRequire(this.handleLanguageChange);
    EnumService.initialize();
    this.state = {
      hideSearchBar: false,
      hideMeetingIcon: false,
      currentLocale: LanguageService.isLanguage('en') ? 'en' : 'fr'
    };

    //Google Analytics initialization
    enableDisableGA();

    /* this will change for SN */
    this.hideSearchBar = this.hideSearchBar.bind(this);
    this.showSearchBar = this.showSearchBar.bind(this);
    this.showWebsite = this.showWebsite.bind(this);
  }
  hideSearchBar() {
    this.setState({
      hideSearchBar: true,
    });
  }
  showSearchBar() {
    this.setState({
      hideSearchBar: false,
    });
  }
  componentDidMount() {
    setTimeout(this.showWebsite, 1000);
    /* why is this done? */
    /* GSBM-1253 This is a hack to give the website time to load the fonts 
       If you have a better solution please lead the way but this is also done on TD.com
    */
    /* why is this done? */

  }
  showWebsite() {
    document.body.style.visibility = "visible";
  }
  handleLanguageChange = (language) => {

    this.setState({
      currentLocale: language
    });
  }

  cookieConsentAccepted() {
    enableGA();
  }

  cookieConsentDeclined() {
    disableGAByDeclinedCookieConsent();
  }

  render() {
    reportWebVitals();
    window.scrollTo({ top: 0 });
    
    return (
      <IntlProvider locale={this.state.currentLocale} messages={messages[this.state.currentLocale]}>
        <div className="tds-sn-app">
          <Router>
            {!((window.location.pathname).includes("file", "en-ca", "fr-ca")) && 
              <><HeaderNavComponent
                hideSearchBar={this.state.hideSearchBar}
                lang={this.state.currentLocale} />
                <RouteChangeDetectorComponent
                  hideSearchBar={this.hideSearchBar}
                  showSearchBar={this.showSearchBar} />
              </>
            }
            <main>
              <Switch>              
                <Route path={ROUTE_PATH_CURRENT_OFFERINGS} exact component={CurrentOfferingComponent}/>
                <Route path={ROUTE_PATH_PREVIOUS_OFFERINGS} exact component={PreviousOfferingComponent}/>
                <Route path={ROUTE_PATH_NOTE_DETAILS} exact component={ProductDetailsPageComponent}/>
                <Route path={ROUTE_PATH_EVENTS_CORPORATE} exact component={CorporateEventDetailsPageComponent}/>
                <Route path={ROUTE_PATH_HOME} exact component={HomePageComponent}/>
                <Route path={ROUTE_PATH_SEARCH} exact component={SearchComponent}/>
                <Route path={ROUTE_PATH_EVENTS} exact component={EventsPageContainerComponent}/>
                <Route path={ROUTE_PATH_CONTACT_US} exact component={ContactUsComponent}/>
                <Route path={ROUTE_PATH_SN_LEGAL_DISCLOSURE} exact component={SNLegalDisclosureComponent}/>
                <Route path={ROUTE_PATH_INVESTORS_INFO} exact component={InvestorsInfoComponent}/>
                <Route path={ROUTE_PATH_RESOURCES_INVESTOR_FRAMEWORK} exact component={InvestorFrameworkComponent}/>
                <Route path={ROUTE_PATH_RESOURCES_PRODUCT_INFORMATION} exact component={ProductInformationComponent}/>
                <Route path={ROUTE_PATH_RESOURCES_FAQS} exact component={FaqsComponent}/>
                <Route path={ROUTE_PATH_PRINT} exact component={PrintComponent}/>
                <Route path={ROUTE_PATH_PREFERENCES} exact component={Preferences}/>
                <Route path={ROUTE_PATH_PRIIP_PAGE} exact component={Priip}/>
                <Route path={ROUT_PATH_404_PAGE} component={FourOFourPageComponent} />
                <Route path="/:something" component={FourOFourPageComponent} />   
                <Redirect match={"/"} to={ROUTE_PATH_HOME} />                                
              </Switch>
            </main>
            {!((window.location.pathname).includes("file", "en-ca", "fr-ca")) && 
              <FooterComponent />
            }
            <CookieConsentBanner acceptCallback={this.cookieConsentAccepted} declineCallBack={this.cookieConsentDeclined}/>
          </Router>
        </div>
      </IntlProvider>
    );
  }
}
