import HttpClient from "../utils/HttpClient";

export default class PriipService {
  static getPriipData(page) {
    return HttpClient.get(`/api/v1/priip/search?page=${page}&size=30`);
  }

  static getPriipSearchData(query, page) {
    return HttpClient.get(`/api/v1/priip/search?isin=${query}&page=${page}&size=30`);
  }

  static downloadPriipData(id) {
    return HttpClient.get(`/api/v1/priip/download/${id}`);
  }
}

export const priipDownload = async (id, name) => {
  try {
    const response = await fetch(`/api/v1/priip/download/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    });
    if (!response.ok) {
      throw new Error("Failed");
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}.pdf`);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
  } catch {
    console.error("download failed");
  }
};

export const handleCopyPriip = async (id) => {
  try {
    const url = `${window.location.origin}/api/v1/priip/download/${id}`;
    await navigator.clipboard.writeText(url);
  } catch {
    console.error("copy failed");
  }
};
